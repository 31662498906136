<template>
    <div class="address-edit">
        <van-address-edit
            class="edit"
            :area-list="areaList"
            :show-delete="showDelete"
            :address-info="addressInfo"
            show-set-default
            show-search-result
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
        />
    </div>
</template>

<script>
import areaList from "@/assets/area";

export default {
    name: "AddressEdit",
    data() {
        return {
            areaList,
            searchResult: [],
            addressInfo: {},
            isEdit: false,
            showDelete: false
        };
    },
    methods: {
        async onSave(content) {
            console.log(content);
            let { name, tel, province, city, county: area, addressDetail: detail, isDefault: is_default, areaCode: area_code } = content;
            is_default = is_default ? 1 : 0;
            if (this.isEdit) {
                // 编辑
                let { id: adr_id } = content;
                const res = await axios.post("/change_my_addr", { name, tel, province, city, area, detail, is_default, area_code, adr_id });
                console.log(res);
                if (res.code == 1) {
                    const path = sessionStorage.getItem("chooseAddr");
                    if (path) {
                        const data = {
                            ...res.data,
                            address: res.data.province + res.data.city + res.data.area + res.data.detail
                        }
                        sessionStorage.setItem("chooseAddr", JSON.stringify(data));
                        this.$router.push({ path });
                    } else {
                        this.$router.push("/addrlist");
                    }
                } else {
                    this.$toast(res.message);
                }
            } else {
                // 新增
                const res = await axios.post("/add_my_addr", { name, tel, province, city, area, detail, is_default, area_code });
                console.log(res);
                if (res.code == 1) {
                    const path = sessionStorage.getItem("chooseAddr");
                    if (path) {
                        const data = {
                            ...res.data,
                            address: res.data.province + res.data.city + res.data.area + res.data.detail
                        }
                        sessionStorage.setItem("chooseAddr", JSON.stringify(data));
                        this.$router.push({ path });
                    } else {
                        this.$router.push("/addrlist");
                    }
                } else {
                    this.$toast(res.message);
                }
            }
        },
        async onDelete(content) {
            console.log(content);
            const res = await axios.post("/del_my_addr", { adr_id: content.id });
            if (res.code == 1) {
                this.$router.push("/addrlist");
            } else {
                this.$toast(res.message);
            }
        },
    },
    created() {
        let addr = sessionStorage.getItem("addrInfo");
        if (addr) {
            this.showDelete = true
            this.isEdit = true;
            addr = JSON.parse(addr);
            let { id, name, tel, province, city, area: county, isDefault, detail: addressDetail, areaCode } = addr;
            isDefault = !!isDefault;
            this.addressInfo = { id, name, tel, province, city, county, isDefault, addressDetail, areaCode };
        }
    },
    beforeRouteLeave(to, form, next) {
        sessionStorage.removeItem("addrInfo");
        next();
    }
};
</script>

<style lang="less">
.address-edit {
    background-color: rgb(247, 248, 250);
    min-height: 100vh;
}
</style>
